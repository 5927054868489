.wrapper {
  margin: 100px auto;
  max-width: 700px;
  width: 80%;
  min-height: 70vh;
  height: max-content;
  border-radius: 5px;
  display: flex;
  background: rgb(247, 244, 244);
  .left {
    background: #EAEBF6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    img {
      object-fit: contain;
    }
    h3 {
      color: rgb(250, 250, 250);
      z-index: 1;
      margin: 0;
    }
    .underlay {
      width: 100%;
      height: 100%;
      border-radius: inherit;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #4a4e7a49;
      z-index: 0;
    }
  }
  .right {
    margin: auto;
  }
  @media only screen and (max-width: 769px) {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .left {
      width: 100%;
      min-height: 20vh;
      max-height: 20vh;
      border-bottom-left-radius: 0px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      img {
        width: 70px;
        height: 70px;
      }
      h3 {
        font-size: 22px;
        width: 70%;
      }
    }
    .right {
      width: 90%;
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }
  
  
  @media only screen and (min-width: 769px) {
    justify-content: space-between;
    align-items: center;
    .left {
      width: 35%;
      height: 100%;
      min-height: 70vh;
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      img {
        width: 100px;
        height: 100px;
      }
      h3 {
        font-size: 36px;
      }
    }
    .right {
      width: 65%;
    }
  }
}