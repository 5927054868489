/* contact us */

.contact-us {
  min-height: 100vh;
  height: max-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 10% auto;
  margin-bottom: 0;
  position: relative;
}

.contact-us .content {
  max-width: 400px;
  width: 45%;
}

.contact-us form {
  display: flex;
  flex-direction: column;
  background: #F0F4F8;
  width: 50%;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  padding: 7% 5%;
  box-sizing: border-box;
}

.contact-us input, textarea, button {
  height: 50px;
  width: 80%;
  border-radius: 5px;
  border: 0;
  margin: 10px 0;
  padding: 0 10px;
}

.contact-us textarea {
  height: 100px;
  resize: none;
  padding: 10px;
}
.contact-us button {
  background: #7B86CB;
  color: #FFFFFF;
  width: 83%;
  opacity: 0.8;
  transition: opacity 0.2s;
  cursor: pointer;
}

.contact-us button:hover {
  opacity: 1;
}

@media only screen and (max-width: 600px) {
  .contact-us {
    flex-direction: column;
    padding: 0 5%;
  }
  .contact-us form {
    display: flex;
    flex-direction: column;
    background: #F0F4F8;
    width: 90%;
    justify-content: center;
    align-items: center;
    position: relative;
    right: 0;
    padding: 7% 5%;
    box-sizing: border-box;
    margin: 20px 0;
  }
  .contact-us .content {
    width: 80%;
  }
}

@media only screen and (min-width: 600px) {
  .contact-us {
    flex-direction: row;
    padding: 0 15%;
  }
}
