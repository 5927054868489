.bank-wrapper {
  padding: 2% 2%;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  box-sizing: border-box;
  label {
    color: #66676D;
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
  }
  .bank-card-wrapper {
    display: flex;
    flex-wrap: wrap;
    .add-bank {
      box-shadow: none !important;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: 1px solid rgba(149, 157, 165, 0.2);
      img {
        width: 40px;
        height: 40px;
        opacity: 0.5;
        margin-bottom: 20px;
        object-fit: contain;
      }
    }
  }
}

.form {
  text-align: left;
  max-width: 100%;
  width: 100%;
  height: 100%;
  background: #FFF;
  margin: 20px auto;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .btn-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    button {
      cursor: pointer;
      width: 48%;
      &.add-bank {
        background: #ece0d1;;
      }
    }
  }
  .select-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .react-select-container {
      width: 100%;
      cursor: pointer;
    }
  }
}

.confirm-show-details {
  text-align: center;
  p {
    text-align: center;
  }
  .btn-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    button {
      margin: 5px;
      margin-top: 10px !important;
      margin-bottom: 5px !important;
      width: 46%;
      min-width: 100px;
    }
  }
}

.bank-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
}

.bank-card {
  width: 240px;
  height: 160px;
  margin: 10px;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  border-left: 35px solid #333333;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 1px 24px;
  &:hover {
    box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;
  }
  .top {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .bottom {
    width: 100%;
    h4, h3 {
      padding: 0;
      margin: 0;
      text-align: left;
      overflow: hidden;
      width: 100%;
      // color: #88A7F8;
    }
  }
  .name {
    font-weight: 600;
    font-size: 18px;
    width: 80%;
  }
  p {
    padding: 0;
    margin: 0;
    text-align: left;
  }
  .details {
    font-weight: 500px;
    p {
      margin: 3px 0;
    }
  }
  .controls {
    position: absolute;
    height: 100%;
    top: 0;
    right: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    button, .options {
      background: transparent !important;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      height: 30px;
      img {
        width: 20px;
        height: 20px;
        object-fit: contain;
      }
      .actions {
        bottom: 10px;
        right: 20px;
        width: 70px;
        height: 70px;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        background: #fff;
        padding: 10px;
        border-radius: 8px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        button {
          position: absolute;
          top: 0;
          right: 5px;
          width: 15px;
          height: 15px;
        }
        span:hover, div:hover {
          width: 100%;
          font-size: 12px;
          text-align: left;
          padding: 5px;
          box-sizing: border-box;
          margin: 6px 0;
          border-radius: 5px;
        }
        span:hover, div:hover {
          background: gray;
          color: #fff;
        }
      }
    }
    .options {
      bottom: 20px;
      right: 10px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 15px;
        height: 15px;
        object-fit: contain;
      }
    }
  }
}

.add-bank-wrapper {
  width: 90%;
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}