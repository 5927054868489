.App {
  text-align: center;
  width: 100vw;
  min-height: 100vh;
}

body {
  background: #F6F9FF;
  margin: 0;
  padding: 0;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}



@media only screen and (max-width: 600px) {
  
}
