.pfas-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.788);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    width: 80vw;
    max-width: 800px;
    background-color: #fff;
    height: 80vh;
    border-radius: 10px;
    position: relative;
    padding: 30px;
    box-sizing: border-box;
    .pfas {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      flex-wrap: wrap;
      justify-content: center;
      height: 90%;
      width: 100%;
      overflow: auto;
    }
    button {
      width: 40px;
      height: 40px;
      position: absolute;
      cursor: pointer;
      @media only screen and (max-width: 450px) {
        top: 10px;
        right: 20px;
      }    
      
      @media only screen and (min-width: 450px) {
        top: 20px;
        right: 30px;
      }
    }
    p {
      align-self: flex-start;
      width: max-content;
      padding: 0;
      margin: 0;
      font-weight: 900;
      @media only screen and (max-width: 450px) {
        font-size: 18px;
      }    
      
      @media only screen and (min-width: 450px) {
        font-size: 24px;
      }
    }
    .pfa-card {
      background: #EAEBF6;
      width: 200px;
      height: 100px;
      border-radius: 10px;
      margin: 10px;
      padding: 15px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      text-align: left;
      span {
        margin-bottom: 5px;
        font-size: 18px;
        font-weight: 900;
      }
      b {
        font-size: 13px;
      }
    }
  }
}