.debit-wrapper {
  margin: 100px auto;
  max-width: 700px;
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1024px) {
  .debit-wrapper {
    justify-content: center;
  }
}


@media only screen and (min-width: 1024px) {
  .debit-wrapper {
    justify-content: space-between;
  }
}