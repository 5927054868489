.header {
  height: 60px;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  h3 {
    text-transform: capitalize;
  }
  .sec-nav {
    display: flex;
    width: max-content;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    .item {
      width: max-content;
      height: 40px;
      margin: 0 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      img {
        margin-right: 10px;
        width: 15px;
        height: 15px;
      }
    }
  }
}