nav {
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  background: transparent;
  z-index: 2;
  color: #ffffffec;
  transition: all 0.5s ease-in-out;
}

a {
  text-decoration: none;
}

nav .left {
  display: flex;
  flex-wrap: wrap;
}

nav .loginBtn, nav .loginBtnMobile {
  background-color: #7B86CB !important;
  border: 0;
  color: #FFFFFF;
  height: 40px;
  width: 135px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  opacity: 0.8;
  transition: opacity 0.2, width 0.2;
  cursor: pointer;
}

.loginBtn:hover {
  opacity: 1;
  width: 138px;
}

nav button a {
  text-decoration: none;
  color: #FFFFFF;
}

.nav-item-wrapper {
  flex-direction: row;
}

.left img {
  height: 28px;
  width: 105px;
  object-fit: contain;
  align-self: center;
  z-index: 1;
}

.nav-item-wrapper li {
  list-style-type: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  color: #18191F;
  font-weight: bold;
  margin: 0 16px;
}

.nav-item-wrapper li:hover {
  background-color: #7b86cb38;
}

.nav-item-wrapper li a {
  text-decoration: none;
  color: #18191F;
  font-weight: bold;
  margin: 0 16px;
}

@media only screen and (max-width: 1000px) {
  nav {
    padding: 0 7%;
  }
  .nav-item-wrapper {
    display: none;
  }
  nav .loginBtn {
    display: none;
  }
  .mobilenav-item {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    background: rgba(3, 3, 3, 0.8);
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    z-index: 9;
  }
  .mobile-nav-item-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: center;
    list-style: none;
    padding: 10% 0;
    height: 100%;
    width: 60%;
    background-color: #FFFFFF;
    margin: 0;
    align-self: flex-end;
  }
  .mobile-nav-item-wrapper button {
    align-self: center;
  }
  .mobile-nav-item-wrapper li {
    padding: 0 !important;
    border-radius: 5px;
    cursor: pointer;
    color: #18191F;
    font-weight: bold;
    margin: 10px 0 !important;
    list-style: none;
  }
  .closeBtn {
    position: absolute;
    width: 15px;
    height: 15px;
    object-fit: contain;
    top: 10px;
    right: 10px;
  }
  .openBtn {
    display: flex;
    width: 25px;
    height: 25px;
    object-fit: contain;
  }
}


@media only screen and (min-width: 1000px) {
  nav {
    padding: 0 11%;
  }
  .nav-item-wrapper {
    display: flex;
  }
  nav .loginBtn {
    display: flex;
  }
  .mobilenav-item, .mobile-nav-item-wrapper {
    display: none;
  }
  .openBtn {
    display: none;
  }
  nav .left img {
    transform: scale(1.2);
    height: 40px;
    width: 130px;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  width: 160px;
  min-height: 70px;
  box-shadow: 0px 0px 10px 0px #F6F9FF;
  padding: 12px 16px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  a {
    margin: 5px 0;
    padding: 5px 10px;
    width: 80%;
    border-radius: 5px;
    &:hover {
      background-color: #7b86cb38;
    }
  }
}
