.multi-select {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  box-sizing: border-box;
  .dropdown-heading {
    button {
      height: 20px !important;
      width: 20px !important;
    }
  }
  .dropdown-heading-value {
    text-align: left !important;
    span {
      font-size: 14px !important;
      color: #000 !important;
      display: flex !important;
      flex-direction: row !important;
      flex-wrap: nowrap !important;
      overflow: visible !important;
    }
  }

  li {
    color: #000 !important;
    label {
      color: #000 !important;
      input {
        width: 15px !important;
        height: 15px !important;
      }
      span {
        color: #000 !important;
        font-size: 14px;
      }
    }
  }
  .custom-value {
    // width: 300px !important;
    margin: 0 5px;
    padding: 5px;
    border-radius: 5px;
    background: rgb(167, 160, 177);
    display: flex !important;
    flex-direction: row !important;
  }
}
