.steps {
  display: flex;
  justify-content: center;
  align-items: center;
  .step {
    display: flex;
    justify-content: center;
    align-items: center;
    .sphere {
      border: 1px solid #05377F;
      width: 5px;
      height: 5px;
      border-radius: 10px;
    }
    .line {
      width: 20px;
      // height: 1px;
      border: 1px solid #05377F;
      margin: 0 5px;
      background: #05377F;
    }
    &:last-child {
      .line {
        display: none;
      }
    }
    &.selected {
      .sphere {
        background: #05377F;
      }
    }
  }
}

.btn-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.form-wrapper {
  padding: 20px 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}