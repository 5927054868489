.table-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .goto {
      input {
        width: 30px;
        height: 25px;
        margin: 0 15px;
      }
    }
    select {
      width: 100px;
      height: 30px;
    }
  }
  button {
    width: max-content;
    height: 40px;
    background-color: #7B86CB;
    font-weight: 600;
    color: #fff;
    margin: 5px;
    cursor: pointer;
  }
}

.gen-table-container {
  width: 100%;
  flex: 1;
  padding: 30px;
  box-sizing: border-box;
  .search-input {
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    box-sizing: border-box;
    input {
      height: 40px;
      padding: 0 10px;
      min-width: 300px;
    }
  }
  .table-wrapper {
    max-width: 100%;
    overflow: auto;
    box-sizing: border-box;
    table {
      border-collapse: collapse;
      border: 1px solid black;
      background-color: #fff;
      thead {
        tr {
          th {
            border: 1px solid black;
            text-align: left;
            width: max-content;
            word-break: keep-all;
            padding: 10px;
            .head-cell {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-start;
              height: 100%;
              min-width: 100px;
            }
          }
        }
      }
      tbody {
        border: 1px solid black;
        tr {
          border: 1px solid black;
          td {
            border: 1px solid black;
            text-align: left;
            width: max-content;
            padding: 10px;
          }
        }
      }
      .filter-wrapper {
        border: 1px solid black;
        min-width: 100px;
      }
    }
  }
  .filter-toggle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 10px;
    box-sizing: border-box;
    label {
      margin: 0;
      margin: 0 10px;
    }
    input {
      width: 20px;
      height: 20px;
      margin: 0;
      min-width: 0;
    }
  }
}

.table-options {
  width: 50px;
  position: relative;
  display: inline-block;
  .dropdown {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 5px;
    z-index: 1;
  }
  &:hover .dropdown {
    display: block;
  }
  button {
    height: 30px;
    width: 100px;
    padding: 0;
    text-align: left;
    cursor: pointer;
    margin: 2px 0;
    font-weight: 500;
    padding: 0 10px;
    box-sizing: border-box;
    &:hover {
      border: 1px solid black;
    }
  }
}

.table-options:hover .dropdown {
  display: block;
}
