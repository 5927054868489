#header {
  height: 50px;
}
.form {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .form-wrapper {
    width: 100%;
  }
  .form-group {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .select {
    width: 100%;
  }
  .input {
  }
  .submitBtn {
    background: #7B86CB;
    color: #FFF;
  }
  .btn-wrapper {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  p {
    text-align: left;
  }
  @media only screen and (max-width: 450px) {
    .form-group {
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }
    .input {
      width: 100% !important;
    }
  }
  
  
  @media only screen and (min-width: 450px) {
    .form-group {
      justify-content: space-between;
    }
    .input {
      width: 48%;
    }
  }
}

.stepper {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999 !important;
  .step {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    border: 1px solid #000000 !important;
  }
  .dash {
    width: 15px;
    margin: 0 3px;
    height: 1px;
    background: #000000 !important;
  }
  .active {
    background: #000000 !important;
  }
}