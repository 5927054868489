
/* About Us */
.about-us {
  padding: 0 15%;
  min-height: 50vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10% auto;
  max-width: 1000px;
}

.about-us img {
  /* background: #70a0c81f; */
  object-fit: cover;
}

.about-us .content {
  padding: 5%;
}

.about-us a {
  box-shadow: 0 0 0 0 !important;
  text-align: left !important;
  padding: 0 !important;
}

.about-us button {
  background: transparent;
  color: #8C30F5;
  font-weight: bold;
  border: 0;
  padding: 0;
  cursor: pointer;
}

@media only screen and (max-width: 769px) {
  .about-us {
    flex-direction: column;
    padding: 0 5%;
  }
  .about-us img {
    width: 90%;
    height: 50vh;
  }
  .about-us .content {
    width: 80%;
    text-align: center;
  }
  .about-us button {
    align-self: center;
    text-align: center;
  }
}

@media only screen and (min-width: 769px) {
  .about-us {
    flex-direction: row;
    padding: 0 15%;
  }
  .about-us img {
    width: 400px;
    height: 400px;
  }
  .about-us .content {
    padding: 5%;
    width: 400px;
    text-align: left;
  }
  .about-us button {
    align-self: flex-start;
    text-align: left;
  }
}

