.payments {
  margin: 100px auto;
  min-height: 70vh;
  width: 100%;
  #header {
    height: 100px;
  }
  .solution-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .solution {
      border: 1px solid rgba(149, 157, 165, 0.2);
    }
  }
}