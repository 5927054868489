/* solutions */

.solutions {
  width: 100%;
  min-height: 100vh;
  padding: 0 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.section-header {
  max-width: 320px;
  width: 95%;
  text-align: center;
  line-height: 24px;
}

.solutions-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1000px;
}

.solutions-wrapper h2 {
  font-size: 18px;
}

.solution {
  width: 300px;
  text-align: center;
  margin: 4% 0;
  padding: 15px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: box-shadow 0.2s ease-in-out;
  text-decoration: none;
  color: black;
}

.solution:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.solution p {
  line-height: 22px;
  font-size: 14px;
}

.solution button {
  font-weight: bold;
  cursor: pointer;
}

.solution img {
  object-fit: contain;
}

@media only screen and (max-width: 600px) {
  .solutions-wrapper {
    justify-content: center;
  }
}

@media only screen and (min-width: 600px) {
  .solutions-wrapper {
    max-width: 1000px;
  }
}