.input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 10px 0;
}


.input-wrapper input, .input-wrapper .file-input, .input-wrapper select {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 10px;
  border: 1px solid #4b4b4b;
  background: #FCFDFE !important;
  color: #66676D;
  font-size: 14px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  &::placeholder {
    color: #66676d5a;
  }
}

.file-input {
  margin-top: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
}
.file-label {
  align-items: flex-start;
  justify-content: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  p {
    border-radius: 5px;
    margin-top: 10px;
    height: 30px;
    width: max-content !important;
    margin: 4px 0 !important;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px !important;
    width: 70px !important;
  }
  span {
    overflow: hidden;
    margin-left: 5px;
    max-height: 90%;
  }
}

.input-wrapper > .label-wrapper {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}

.input-wrapper label {
  color: #66676D;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.input-wrapper a {
  text-decoration: none !important;
}

.input-wrapper span {
  color: #9FA2B4;
  font-size: 10px;
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: none;
}

.input-wrapper p {
  color: red;
  font-size: 10px;
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: none;
}
