.side-nav {
  width: 180px;
  padding: 0 20px;
  box-sizing: border-box;
  min-height: 100vh;
  position: sticky;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .input {
    border: 1px solid transparent;
    select {
      border: transparent !important;
      outline: none;
      background: #7b86cb36 !important;
      &:active {
        border: transparent !important;
        outline: none;
      }
    }
  }
  .nav-item {
    height: 40px;
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-left: 4px solid transparent;
    cursor: pointer;
    &.active, &:hover {
      background: #D9D9D9;
      border-left: 4px solid #7B86CB;
    }
    img {
      width: 15px;
      height: 15px;
      margin-right: 15px;
    }
    span {
      font-weight: 600;
    }
  }
}