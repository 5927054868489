.documentation {
  height: 100vh;
  margin-top: 70px;
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .open-nav {
    position: absolute;
    height: 40px;
    width: 30px;
    z-index: 90;
    cursor: pointer;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    justify-content: center;
    align-items: center;
    img {
      width: 15px;
      height: 15px;
      object-fit: contain;
      transform: rotate(90deg);
    }
    @media only screen and (max-width: 700px) {
      display: flex;
    }
    @media only screen and (min-width: 700px) {
      display: none;
    }
  }
  .side-nav {
    z-index: 0;
    height: 100%;
    width: 20%;
    min-width: 300px;
    position: relative;
    margin: 0;
    padding: 0;
    background: rgb(255, 255, 255);
    box-shadow: rgba(17, 17, 26, 0.1) 1px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  li {
    list-style-type: none;
  }
  .side-nav-ul {
    width: 80%;
    margin-left: 0;
    padding-left: 0;
  }
  .nav-item {
    width: 100%;
    li {
      display: flex;
      width: 100%;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      &:hover {
        background: rgba(0, 0, 0, 0.193);
        border-radius: 5px;
      }
    }
    .caret {
      width: 15px;
      height: 15px;
      object-fit: contain;
    }
  }
  span {
    color:black;
    font-size: 18px;
    font-weight: 700;
  }
  .openBtn {
    position: absolute;
  }
  .sub-items {
    li {
      color: black;
      margin: 10px 0;
      margin-left: 20px;
      flex: 1;
      width: 60%;
      text-transform: capitalize;
      padding: 10px;
      &:hover, .active-subItem {
        background: rgba(0, 0, 0, 0.193);
        border-radius: 5px;
      }
    }
    .active-subItem {
      background: rgba(0, 0, 0, 0.193);
      border-radius: 5px;
    }
  }
  .doc-wrapper {
    position: relative;
    flex: 1;
    height: 100%;
    overflow: scroll;
    padding: 10px 40px;
    box-sizing: border-box;
    text-align: left;
      h2, h3 {
      text-transform: capitalize;
    }
    li {
      list-style-type: circle;
    }
    pre {
      overflow: scroll;
      padding: 20px;
      border-radius: 5px;
      background: rgb(230, 224, 224);
    }
    .endpoint-table {
      padding: 0;
      border-radius: 5px;
      border: 1px solid rgb(230, 224, 224);
      display: flex;
      flex-direction: column;
      .table-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 2px solid rgb(230, 224, 224);
        span {
          word-break:break-all;
          word-wrap: break-word;
          display: flex;
          flex-direction: column;
          padding: 10px 15px;
          width: 25%;
          flex-wrap: wrap;
          border-left: 1px solid rgb(230, 224, 224);
          border-right: 1px solid rgb(230, 224, 224);
          li {
            list-style: none;
            margin-left: 20px;
          }
        }
      }
      .header {
        text-transform: capitalize;
        margin-top: 0;
        span {
          font-weight: 900;
          font-size: 16px;
          margin: 0;
        }
      }
    }
  }
}