
/* hero */

.hero {
  border: 1px solid transparent;
  background-image: url('../../assets/Background.png');
  background-size: cover;
  margin: 0;
  height: 100vh;
  width: 100%;
}

.hero button {
  background-color: #7B86CB;
  border: 0;
  color: #FFFFFF;
  height: 50px;
  width: 196px;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.2, width 0.2;
}

.hero button:hover {
  width: 200px;
  opacity: 1;
}

.hero button a {
  text-decoration: none;
  color: #FFFFFF;
}

.hero div {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: transparent;
  padding-left: 10%;
  justify-content: center;
}

.hero img {
  position: absolute;
  right: 8%;
  top: 20%;
  height: 80%;
  object-fit: contain;
}

.hero p {
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .hero img {
    display: none;
  }
  .hero p {
    font-size: 42px;
    max-width: 90%;
    line-height: 48px;
  }
  .hero div {
    justify-content: center;
    align-items: center;
    padding: 0;
  }
}


@media only screen and (min-width: 600px) {
  .hero img {
    display: flex;
  }
  .hero p {
    font-size: 52px;
    max-width: 50%;
    text-align: left;
  }
}

@media only screen and (min-width: 1281px) {
  .hero img {
    display: flex;
  }
  .hero p {
    font-size: 72px;
    max-width: 50%;
    text-align: left;
  }
}

