.genericBtn {
  cursor: pointer;
  min-width: 100px;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  border: 0;
  border-radius: 5px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.flat {
  width: 100%;
  background-color: #7B86CB;
  color: #FFFFFF;
  margin: 30px 0;
}

.text {
  color: #7B86CB;
  background: transparent;
  margin: 0;
}

.contained {
  border: 1px solid #7B86CB;
  color: #7B86CB;
  background: transparent;
  margin: 30px 0;
}

.lgBtn {
  width: 100% !important;
}
