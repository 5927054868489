.form {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .form-wrapper {
    width: 100%;
  }
  .form-group {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .select {
    width: 100%;
  }
  .input {
    width: 48%;
  }
  .submitBtn {
    background: #7B86CB;
    color: #FFF;
  }
  .btn-wrapper {
    margin-top: 20px;
  }
  p {
    text-align: left;
  }
}

.stepper {
  display: flex;
  justify-content: center;
  align-items: center;
  .step {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    border: 1px solid #7B86CB;
  }
  .dash {
    width: 15px;
    margin: 0 3px;
    height: 1px;
    background: #7B86CB
  }
  .active {
    background: #7B86CB;
  }
}