.dashboard {
  width: 100vw;
  min-height: 100vh;
  min-width: 800px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background-color: aliceblue;
  .outlet {
    flex: 1;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    a {
      color: red;
      font-size: 12px;
      margin: 10px 0;
    }
  }
}