body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  opacity: 0.8;
}

button:hover {
  opacity: 1;
}

.excel-display {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.788);
  top: 0;
  left: 0;
  z-index: 3;
  .form-wrapper {
    margin-top: 60px;
    width: 100%;
    background: #F6F9FF;
    max-width: 600px;
    width: 90%;
    padding: 10px 20px;
    padding-bottom: 20px;
    border-radius: 5px;
    box-sizing: border-box;
    .form-group {
      flex-direction: row;
      display: flex;
    }
    fieldset {
      border: 0;
    }
    .note {
      font-size: 10px;
      b {
        margin: 0 10px;
      }
    }
    @media only screen and (max-width: 450px) {
      .form-group {
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
      }
      .input {
        width: 100% !important;
      }
    }
    
    
    @media only screen and (min-width: 450px) {
      .form-group {
        justify-content: space-between;
      }
      .input {
        width: 48%;
      }
    }
    .small-top {
      margin-top: 5px;
    }
  }
  .btn-group {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button {
    width: 100px;
    margin: 10px;
  }
  .confirm-delete {
    background: #F6F9FF;
    border-radius: 5px;
    width: 90%;
    height: max-content;
    max-height: 80vh;
    overflow: auto;
    max-width: 600px;
    height: max-content;
    padding: 20px 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 80px auto;
    color:rgb(24, 24, 24);
    h3 {
      text-align: center;
    }
    .item-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        width: 45%;
        min-width: 250px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 10px 0;
        span {
          width: 100%;
        }
        b {
          width: 100%;
        }
      }
    }
    .btn-wrapper {
      margin: 20px 0;
      display: flex;
      justify-content: center;
      button { 
        height: 40px;
        width: 100px !important;
        padding: 0 5px !important;
        cursor: pointer;
        z-index: 0 !important;
      }
    }
  }
}

.excel-display .content {
  background: #F6F9FF;
  border-radius: 5px;
  width: 90%;
  height: 80%;
  margin: 50px auto;
  margin-bottom: 0 !important;
  padding: 5px 20px;
  overflow: scroll;
  .display {
    border: 1px solid #66676D;
    width: max-content;
    height: 85%;
    width: 100%;
    overflow: auto;
    .row {
      width: max-content;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .cell {
        width: 250px;
        height: 30px;
        box-sizing: border-box;
        padding: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #66676D;
      }
      .action {
        width: 120px;
        button {
          height: 25px;
          width: min-content;
          margin: 0;
          cursor: pointer;
        }
      }
      .sn {
        width: 40px;
      }
    }
    .header {
      position: sticky;
      z-index: 1 !important;
      background: #F6F9FF;
      top: 0px;
      .cell {
        font-weight: 800 !important;
      }
    }
  }
}

.react-select-container {
  height: 40px !important;
  width: 100% !important;
  border-radius: 5px !important;
  box-sizing: border-box;
  border: 1px solid #F0F1F7 !important;
  background: #FCFDFE !important;
  color: #66676d;
  border: 1px solid #4b4b4b !important;
  .css-319lph-ValueContainer {
    height: 100% !important;
  }
}

.react-select-container > div:first-of-type {
  height: 100% !important;
  border: 0 !important;
  border-radius: 5px;
}