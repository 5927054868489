.summary-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.788);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    width: 80vw;
    max-width: 800px;
    background-color: #fff;
    height: 80vh;
    border-radius: 10px;
    position: relative;
    padding: 30px;
    box-sizing: border-box;
    .summary-wrapper {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      flex-wrap: wrap;
      justify-content: center;
      height: 90%;
      width: 100%;
      overflow: auto;
    }
    button {
      width: 40px;
      height: 40px;
      position: absolute;
      cursor: pointer;
      @media only screen and (max-width: 450px) {
        top: 10px;
        right: 20px;
      }    
      
      @media only screen and (min-width: 450px) {
        top: 20px;
        right: 30px;
      }
    }
    .summ-title {
      align-self: flex-start;
      width: max-content;
      padding: 0;
      margin: 0;
      font-weight: 900;
      @media only screen and (max-width: 450px) {
        font-size: 18px;
      }    
      
      @media only screen and (min-width: 450px) {
        font-size: 24px;
      }
    }
  }
}

.summary {
  margin-right: 10px;
  padding: 5px 7px;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #EAEBF6;
  border-radius: 5px;
  width: 200px !important;
  text-align: left;
  .summ-detail {
    display: flex;
    flex-direction: column;
  }
}