.products {
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.new-product-wrapper {
  max-width: 300px;
  width: 90%;
  margin: auto;
}

.product-list {
    width: 85vw;
    box-sizing: border-box;
}