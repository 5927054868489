.form {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .form-wrapper {
    width: 100%;
  }
  .form-group {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .select {
    width: 100%;
  }
  .input {
    width: 100%;
  }
  .submitBtn {
    background: #7B86CB;
    color: #FFF;
    width: 100%;
  }
  .btn-wrapper {
    margin-top: 20px;
  }
}