.luc-form {
  .details-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    min-height: 30px;
    margin: 5px 0;
  }
  .previous-download {
    font-size: 16px;
    margin: 30px 0;
    a {
      border: 1px solid blue;
      border-radius: 7px;
      padding: 10px;
    }
  }
  p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 900;
    &.header-title {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
  .download-link {
    font-size: 14px;
  }
  .pension-sub-group {
    text-align: left;
  }
  .form-wrapper {
    width: 100%;
    fieldset {
      border: 0;
    }
    .note {
      font-size: 12px;
      b {
        margin: 0 10px;
      }
    }
    @media only screen and (max-width: 450px) {
      .form-group {
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
      }
      .input {
        width: 100% !important;
      }
    }
    
    
    @media only screen and (min-width: 450px) {
      .form-group {
        justify-content: space-between;
      }
      .input {
        width: 48%;
      }
    }
    .small-top {
      margin-top: 5px;
    }
  }
  .title {
    font-weight: 100;
  }
  .value {
    font-weight: 900;
  }
}

.toast-close-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  button {
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
  }
}

.active-sheet {
  display: none;
}

table {
  border: 1px solid #000;
  tbody {
    tr {
      border: 1px solid #000 !important;
    }
  }
}

.downloadBtn {
  font-size: 12px;
  height: 30px;
}

.link-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
  b {
    color: blue;
    font-size: 14px;
    cursor: pointer;
  }
}

.single-entry-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 12px;
    color: blue;
    cursor: pointer;
  }
}



.smBtn {
  width: 70px;
  // display: none;
  margin-top: 0;
  margin-bottom: 0;
}

.receipt-wrapper {
  .receipts {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: auto;
  }
  .receipt-title {
    margin-bottom: 10px;
  }
  .receipt {
    border: 1px solid rgb(126, 202, 249);
    text-align: left;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    width: max-content;
    margin-right: 5px;
    a {
      padding: 0 !important;
    }
  }
}
