.pension-form {
  .details-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 50px;
    margin: 20px 0;
  }
  .previous-download {
    font-size: 12px;
    margin-top: 30px;
  }
  .summary-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    height: max-content;
  }
  p {
    margin: 0;
    padding: 0;
  }
  .download-link {
    font-size: 14px;
  }
  .pension-sub-group {
    text-align: left;
  }
  .form-wrapper {
    width: 100%;
    fieldset {
      border: 0;
    }
    .note {
      font-size: 12px;
      b {
        margin: 0 10px;
      }
    }
    @media only screen and (max-width: 450px) {
      .form-group {
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
      }
      .input {
        width: 100% !important;
      }
    }
    
    
    @media only screen and (min-width: 450px) {
      .form-group {
        justify-content: space-between;
      }
      .input {
        width: 48%;
      }
    }
    .small-top {
      margin-top: 5px;
    }
  }
  .title {
    font-weight: 100;
  }
  .value {
    font-weight: 900;
  }
}

.toast-close-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  button {
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
  }
}

.active-sheet {
  display: none;
}

table {
  border: 1px solid #000;
  tbody {
    tr {
      border: 1px solid #000 !important;
    }
  }
}

.downloadBtn {
  font-size: 12px;
  height: 30px;
}

.link-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
  b {
    color: blue;
    font-size: 14px;
    cursor: pointer;
  }
}

.single-entry-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 12px;
    color: blue;
    cursor: pointer;
  }
}



.smBtn {
  width: 70px;
  // display: none;
  margin-top: 0;
  margin-bottom: 0;
}

.help {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .underlay {
    width: 100%;
    height: 100%;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .content {
    z-index: 3;
    background: #fff;
    width: 300px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
  .cls-btn {
    position: absolute;
    top: 5px;
    right: 20px;
    width: 30px;
    height: 30px;
    padding: 0;
    cursor: pointer;
    img {
      width: 10px;
      height: 10px;
    }
  }
  p {
    margin: 10px 0 !important;
  }
  h4 {
    margin: 5px 0;
    padding: 0;
  }
}