.button-link {
  width: max-content;
}
.submitBtn {
  background-color: #7B86CB !important;
  color: #FFF;
  width: 200px;
  &.lgBtn {
    width: 100% !important;
  }
}