@import "/src/utils/breakpoints";

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 50vh;
  width: 100vw;
  background-color: #000000e1;
  .top {
    display: flex;
    flex-direction: row;
    padding: 0 8%;
    justify-content: space-between;
    align-items: center;
    color: #F6F6F6;
    flex: 1;
    flex-wrap: wrap;
    height: 80%;
    .home, .section {
      min-width: 100px;
      text-align: left;
      @include mobile {
        width: 100%;
      }
    }

    @include mobile {
      flex-direction: column;
    }

    h5 {
      font-size: 20px;
      color: #FFF;
      margin-bottom: 15px;
    }
    ul {
      list-style: none;
      list-style-position: outside;
      padding: 0;
      
      a {
        text-decoration: none !important;
      }
      li {
        margin: 15px 0;
        color: #E7E7E7;
        font-size: 16px;
        cursor: pointer;
        opacity: 0.8;
        &:hover {
          opacity: 1;
        }
      }
    }
    .subscribe {
      .footer-form {}
    }
    .subscribe, .links, .resources, .home {
      height: 200px;

      @include mobile {
        height: fit-content;
        display: flex;
        flex-direction: column;
      }

      input {
        height: 40px;
        width: 200px;
        background: transparent;
        border: 1px solid #FFF;
        border-radius: 5px;
        padding: 0 5px;
        color: #fff,
      }
      button {
        height: 40px;
        border: 1px solid #ece0d1;
        background: #ece0d1;
        border-radius: 5px;
        padding: 0 20px;
        margin-left: 5px;
        font-weight: 600;
        width: 120px;
      }
    }
  }
  .bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 8%;
    background: #000000;
    min-height: 70px;
    color: #F6F6F6;
    font-size: 14px;
    @include mobile {
      flex-direction: column;
    }

    .brand {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      p {
        font-size: 16px;
      }
    }
    .logo {
      width: 150px;
      height: 70px;
      object-fit: contain;
      margin-right: 10px;
    }
    .s-media {
      img {
        margin: 0 4px;
        height: 30px;
        width: 30px;
        object-fit: contain;
      }
    }
  }
}