.download-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
}

.btn-wrapper {
  width: 100%;
  margin: auto;
  justify-content: space-between;
  .btn-link {
    width: 45% !important;
  }
  button {
    @media only screen and (max-width: 800px) {
      width: 45% !important;
      margin: 0 5px;
    }
    
    
    @media only screen and (min-width: 800px) {
      width: 45% !important;
      margin: 0 5px;
    }
  }
}
.submitBtn {
  background-color: #7B86CB !important;
  color: #FFF;
  width: 200px;
  &.lgBtn {
    width: 100% !important;
  }
}

.form-wrapper {
  width: 80%;
  margin: auto;
  fieldset {
    border: 0;
  }
  .note {
    font-size: 10px;
    b {
      margin: 0 10px;
    }
  }
  @media only screen and (max-width: 450px) {
    .form-group {
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }
    .input {
      width: 100% !important;
    }
  }
  
  
  @media only screen and (min-width: 450px) {
    .form-group {
      justify-content: space-between;
    }
    .input {
      width: 48%;
    }
  }
  .small-top {
    margin-top: 5px;
  }
}